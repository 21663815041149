import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import UnoSection from "../components/uno-section";
import CenteredTextComponent from "../components/centered-text";

// Assets
import headerImage from "../images/footer-pages/careers-head-d.jpg";

const CareersPage = () => (
  <Layout currentPage="careers">
    <UnoSection
      verticalCenter
      backgroundImage={headerImage}
      minHeight="50vh"
      maxWidth="800px"
      bottomPadding
    >
      <div className="thispagetitle">careers at uno</div>
      <CenteredTextComponent
        title="Ready for the hardest job you’ll ever do?"
        text="We’re looking for people who love solving big problems.
        Join our growing team and do the most important work of your life."
      />
    </UnoSection>

    <div className="container" id="alljobs">
      <p
        className="col-md-12"
        style={{ paddingBottom: "100px", textAlign: "left" }}
      >
        All of our openings are currently in New York
      </p>
      <div
        className="row justify-content-md-between col-md-12"
        style={{ paddingBottom: "100px" }}
      >
        <ul className="col-md-6 joblisting">
          <li className="bold-text">Physical Product</li>
          <li>
            <Link to="/careers/product-manager">Product Manager</Link>
          </li>
          <li>
            <Link to="/careers/mechanical-engineer">Mechanical Engineer</Link>
          </li>
          <li>
            <Link to="/careers/electrical-engineer">Electrical Engineer</Link>
          </li>
          <li>
            <Link to="/careers/firmware-engineer">Firmware Engineer</Link>
          </li>
        </ul>

        <ul className="col-md-6 joblisting">
          <li className="bold-text">Digital Product</li>
          <li>
            <Link to="/careers/full-stack-developer">Full-Stack Developer</Link>
          </li>
          <li>
            <Link to="/careers/ios-developer">iOS Developer</Link>
          </li>
          <li>
            <Link to="/careers/android-developer">Android Developer</Link>
          </li>
          <li>
            <Link to="/careers/ux-designer">Lead UX Designer</Link>
          </li>
          <li>
            <Link to="/careers/design-director">Design Director</Link>
          </li>
        </ul>

        <ul className="col-md-6 joblisting">
          <li className="bold-text">Marketing</li>
          <li>
            <Link to="/careers/director-of-business-development">
              Director of Business Development
            </Link>
          </li>
          <li>
            <Link to="/careers/marketing-associate">Marketing Associate</Link>
          </li>
        </ul>

        <ul className="col-md-6 joblisting">
          <li className="bold-text">Sales</li>
          <li>
            <Link to="/careers/b2b-sales-lead">B2B Sales Lead</Link>
          </li>
        </ul>

        <ul className="col-md-6 joblisting">
          <li className="bold-text">Operations</li>
          <li>
            <Link to="/careers/chief-sustainability-officer">
              Chief Sustainability Officer
            </Link>
          </li>
          <li>
            <Link to="/careers/head-of-operations">Head of Operations</Link>
          </li>
        </ul>

        <ul className="col-md-6 joblisting">
          <li className="bold-text">General Application</li>
          <li>
            If you don't see a current opening that matches your expertise but
            interested in joining our team, please{" "}
            <a href="mailto:careers@introducing.uno">email us</a> with your
            resume and a cover letter.
          </li>
        </ul>
      </div>
    </div>
  </Layout>
);

export default CareersPage;
