import React from "react";
import styled from "styled-components";

const RegularUnoSection = styled.div`
  padding-top: 110px;
  ${props => props.minHeight && `min-height: ${props.minHeight};`} 
  ${props =>
    props.backgroundColor && `background-color: ${props.backgroundColor};`} 
  ${props => props.color && `color: ${props.color};`} 
  ${props =>
    props.bottomPadding &&
    `padding-bottom: ${
      props.bottomPadding === true ? "160px" : props.bottomPadding
    };`}

  p, h1 {
    max-width: ${props => `${props.maxWidth}` || "none"};
    margin-left: auto;
    margin-right: auto;
  }
  ${props =>
    props.backgroundImage &&
    `
    color: #f5f4f2;
    background-image: url(${props.backgroundImage});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  `};

  ${props => props.marginBottom && `margin-bottom: ${props.marginBottom};`};
  ${props => props.marginTop && `margin-bottom: ${props.marginTop};`};
`;

const VerticalCenterUnoSection = styled(RegularUnoSection)`
  padding-bottom: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  //height: 100vh;
`;

const UnoSection = ({ children, verticalCenter, style, ...props }) =>
  verticalCenter ? (
    <VerticalCenterUnoSection {...props} style={style}>
      <div className="container">
        <div className="row">
          <div className="col-md-12">{children}</div>
        </div>
      </div>
    </VerticalCenterUnoSection>
  ) : (
    <RegularUnoSection {...props} style={style}>
      <div className="container">
        <div className="row">
          <div className="col-md-12">{children}</div>{" "}
        </div>
      </div>
    </RegularUnoSection>
  );

export default UnoSection;
