import React from "react";

const CenteredTextComponent = ({ title, text }) => (
  <>
    <h1>{title}</h1>
    <p>{text}</p>
  </>
);

export default CenteredTextComponent;
